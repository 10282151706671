import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import { GridSeven } from "../components/grid-seven"
// import Breadcrumb from "../components/breadcrumb"
import Seo from "../components/seo"
import SearchBar from "../components/search-bar"

const ServicesPage = (props) => {
  let keywords = [
    "Seat Belt Repair",
    "Seat Belt Coloring",
    "Battery Cable Repair",
    "Steering Column Sensor Repair",
    "SRS Airbag Module Repair",
    "Convertible Roll Bar Repair"
  ]
  let isServicesPage = true
  let title = "Our Services"
  // const windowGlobal = typeof window !== 'undefined' && window

  // if (windowGlobal.localStorage.getItem("vehicleMake") === "" || windowGlobal.localStorage.getItem("vehicleMake") === null || windowGlobal.localStorage.getItem("vehicleYear") === "" || windowGlobal.localStorage.getItem("vehicleYear") === null) {
  //   windowGlobal.localStorage.setItem("vehicleMake", "")
  //   windowGlobal.localStorage.setItem("vehicleYear", "")
  // }

  const data = useStaticQuery(graphql`
      query{
            allContentfulSingleStageSeatBeltRepairService {
              edges {
                node {
                  image {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                    description
                    title
                  }
                  description {
                    childMarkdownRemark {
                      html
                    }
                  }
                  servicePageBodyText {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
            allContentfulDualStageSeatBeltRepairService {
              edges {
                node {
                  image {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                    description
                    title
                  }
                  serviceCardDescription {
                    childMarkdownRemark {
                      html
                    }
                  }
                  servicePageBodyText {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
            allContentfulTripleStageSeatBeltRepairService {
              edges {
                node {
                  image {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                    description
                    title
                  }
                  serviceCardDescription {
                    childMarkdownRemark {
                      html
                    }
                  }
                  servicePageBodyText {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
            allContentfulPretensionerService {
              edges {
                node {
                  image {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                    description
                    title
                  }
                  serviceCardDescription {
                    childMarkdownRemark {
                      html
                    }
                  }
                  servicePageBodyText {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
            allContentfulSeatBeltColoringService {
              edges {
                node {
                  image {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                    description
                    title
                  }
                  serviceCardDescription {
                    childMarkdownRemark {
                      html
                    }
                  }
                  servicePageBodyText {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
            allContentfulSeatBeltWebbingReplacement {
              edges {
                node {
                  image {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                    description
                    title
                  }
                  serviceCardDescription {
                    childMarkdownRemark {
                      html
                    }
                  }
                  servicePageBodyText {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }  
            allContentfulSeatCoverStitching {
              edges {
                node {
                  image {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                    description
                    title
                  }
                  serviceCardDescription {
                    childMarkdownRemark {
                      html
                    }
                  }
                  servicePageBodyText {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            } 
            allContentfulFullSeatReupholstering {
              edges {
                node {
                  image {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                    description
                    title
                  }
                  serviceCardDescription {
                    childMarkdownRemark {
                      html
                    }
                  }
                  servicePageBodyText {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }     
            allContentfulBatteryCableRepairService {
              edges {
                node {
                  image {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                    description
                    title
                  }
                  serviceCardDescription {
                    childMarkdownRemark {
                      html
                    }
                  }
                  servicePageBodyText {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
            allContentfulSteeringColumnSensorRepairService {
              edges {
                node {
                  image {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                    description
                    title
                  }
                  serviceCardDescription {
                    childMarkdownRemark {
                      html
                    }
                  }
                  servicePageBodyText {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
            allContentfulSrsAirbagModuleRepairService {
              edges {
                node {
                  image {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                    description
                    title
                  }
                  serviceCardDescription {
                    childMarkdownRemark {
                      html
                    }
                  }
                  servicePageBodyText {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
            allContentfulConvertibleRollBarService {
              edges {
                node {
                  image {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                    description
                    title
                  }
                  serviceCardDescription {
                    childMarkdownRemark {
                      html
                    }
                  }
                  servicePageBodyText {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }    
      }
    `)

  return (
    <Layout>
      <Seo
        title="Post Collision Services - L&D Solutions Inc."
        location={props.location.pathname}
        keywords={keywords}
        description="L & D Solutions offers post accident seat belt repair services using 100% OEM parts. We also offer services dealing with Seat Belt Repair, Seat Belt Coloring, Battery Cable Repair, 
        Steering Column Sensor Repair, SRS Airbag Module Repair, Convertible Roll Bar Repair, Upholstery and more!"
        isServicesPage={isServicesPage}
      />
      {/* <nav className="flex container mx-auto px-4 py-3 shadow-4xl" aria-label="Breadcrumb">
        <Breadcrumb links={[
          { to: '/', label: 'Home' },
          { to: '/services', label: 'Services' },
        ]} />
      </nav> */}
      <div className="pt-16">
        <SearchBar />
      </div>
      <div className="pt-16">
        <GridSeven title={title}
          singleStage={data.allContentfulSingleStageSeatBeltRepairService.edges}
          dualStage={data.allContentfulDualStageSeatBeltRepairService.edges}
          tripleStage={data.allContentfulTripleStageSeatBeltRepairService.edges}
          pretensioner={data.allContentfulPretensionerService.edges}
          seatBeltColor={data.allContentfulSeatBeltColoringService.edges}
          seatBeltWebbing={data.allContentfulSeatBeltWebbingReplacement.edges}
          seatCoverStitching={data.allContentfulSeatCoverStitching.edges}
          fullSeatReupholstering={data.allContentfulFullSeatReupholstering.edges}
          batteryCable={data.allContentfulBatteryCableRepairService.edges}
          steeringColumn={data.allContentfulSteeringColumnSensorRepairService.edges}
          srsAirbag={data.allContentfulSrsAirbagModuleRepairService.edges}
          convertibleRoll={data.allContentfulConvertibleRollBarService.edges}
        />
      </div>
    </Layout>
  )
}
export default ServicesPage