import React, { Component } from "react"
import { Link } from "gatsby"
import { RedButton } from "../components/button-red"
import { GatsbyImage } from "gatsby-plugin-image"
import Breadcrumb from "../components/breadcrumb"

export class GridSeven extends Component {
    render() {
        return (
            <div className="container mx-auto">
                <div className="hidden lg:block translate-x-[260px]">
                    <h2 className="px-5 font-bold text-2xl lg:text-3xl text-center lg:text-left lg:px-48 lg:translate-y-[10px] xl:px-[330px] 2xl:px-[360px]">{this.props.title}</h2>
                </div>
                <div className="lg:flex -mt-5">
                    <div className="px-8 w-3/4 mx-auto">
                        <nav className="flex container mx-auto py-3 shadow-4xl lg:translate-y-[-90px] lg:translate-x-[40px] lg:pt-10" aria-label="Breadcrumb">
                            <Breadcrumb links={[
                                { to: '/', label: 'Home' },
                                { to: '/services', label: 'Services' },
                            ]} />
                        </nav>

                        <div className="lg:hidden border rounded-xl p-5  mx-auto mb-10">
                            <span className="font-bold">All Services</span>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-repair-service/single-stage-seat-belts'>
                                    Single Stage Seat Belt Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-repair-service/dual-stage-seat-belt'>
                                    Dual Stage Seat Belt Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-repair-service/triple-stage-seat-belt'>
                                    Triple Stage Seat Belt Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-repair-service/pretensioner'>
                                    Pretensioner Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-coloring-service/seat-belt-coloring'>
                                    Seat Belt Coloring
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to="/services/seat-belt-webbing-replacement-service/seat-belt-webbing-replacement">
                                    Webbing Replacement
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/deployed-seat-repair-service/deployed-seat-repair/'>
                                    Deployed Seat Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-reupholstering-service/seat-reupholstering'>
                                    Seat Reupholstering
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/battery-cable-repair-service/battery-cable'>
                                    Battery Cable Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/steering-column-sensor-repair-service/collapsible-steering-sensor'>
                                    Steering Column Sensor Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/srs-airbag-module-repair-service/airbag-module-reset'>
                                    SRS Airbag Module Repair
                                </Link>
                            </div>
                            <div className="pt-3 pb-5">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/convertible-roll-bar-repair-service/convertible-roll-bar'>
                                    Convertible Roll Bar Repair
                                </Link>
                            </div>
                            {/* <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/deployed-headrest-repair-service/deployed-headrest'>
                                    Deployed Headrest Repair
                                </Link>
                            </div> */}
                            <span className="font-bold">All Products</span>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/airbag-plugs/airbag-plugs/'>
                                    Airbag Plugs
                                </Link>
                            </div>
                        </div>


                        <div className="hidden lg:block border rounded-xl p-5 w-3/4 2xl:w-1/2 translate-x-[60px] translate-y-[-90px]">
                            <span className="font-bold">All Services</span>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-repair-service/single-stage-seat-belts'>
                                    Single Stage Seat Belt Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-repair-service/dual-stage-seat-belt'>
                                    Dual Stage Seat Belt Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-repair-service/triple-stage-seat-belt'>
                                    Triple Stage Seat Belt Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-repair-service/pretensioner'>
                                    Pretensioner Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-belt-coloring-service/seat-belt-coloring'>
                                    Seat Belt Coloring
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to="/services/seat-belt-webbing-replacement-service/seat-belt-webbing-replacement">
                                    Webbing Replacement
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/deployed-seat-repair-service/deployed-seat-repair/'>
                                    Deployed Seat Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/seat-reupholstering-service/seat-reupholstering'>
                                    Seat Reupholstering
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/battery-cable-repair-service/battery-cable'>
                                    Battery Cable Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/steering-column-sensor-repair-service/collapsible-steering-sensor'>
                                    Steering Column Sensor Repair
                                </Link>
                            </div>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/srs-airbag-module-repair-service/airbag-module-reset'>
                                    SRS Airbag Module Repair
                                </Link>
                            </div>
                            <div className="pt-3 pb-5">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/convertible-roll-bar-repair-service/convertible-roll-bar'>
                                    Convertible Roll Bar Repair
                                </Link>
                            </div>
                            {/* <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/deployed-headrest-repair-service/deployed-headrest'>
                                    Deployed Headrest Repair
                                </Link>
                            </div> */}
                            <span className="font-bold">All Products</span>
                            <div className="pt-3">
                                <Link className="hover:text-lndred transition duration-150 ease-in-out" to='/services/airbag-plugs/airbag-plugs/'>
                                    Airbag Plugs
                                </Link>
                            </div>
                        </div>
                    </div>
                    <p className="text-3xl font-bold pb-3 lg:hidden px-8">Automotive</p>
                    <div className="px-5 pt-5 pb-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 text-center gap-9 md:gap-5 text-white lg:mt-10 xl:translate-x-[-40px] 2xl:translate-x-[-200px]">

                    <div className="max-w-sm md:max-w-lg mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/seat-belt-repair-service/single-stage-seat-belts">
                            <GatsbyImage image={this.props.singleStage[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title={this.props.singleStage[0].node.image.title} alt={this.props.singleStage[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Single Stage Seat Belt</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.singleStage[0].node.description.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/seat-belt-repair-service/single-stage-seat-belts" buttonText="Repair My Seat Belt" title="Explore Seat Belt Repair Service" alt="Explore Seat Belt Repair Service" invert />
                            </div>
                        </div>
                    </div>

                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/seat-belt-repair-service/dual-stage-seat-belt">
                            <GatsbyImage image={this.props.dualStage[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title={this.props.dualStage[0].node.image.title} alt={this.props.dualStage[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Dual Stage Seat Belt</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.dualStage[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/seat-belt-repair-service/dual-stage-seat-belt" buttonText="Repair My Seat Belt" title="Explore Seat Belt Repair Service" alt="Explore Seat Belt Repair Service" invert />
                            </div>
                        </div>
                    </div>

                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/seat-belt-repair-service/triple-stage-seat-belt">
                            <GatsbyImage image={this.props.tripleStage[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title={this.props.tripleStage[0].node.image.title} alt={this.props.tripleStage[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Triple Stage Seat Belt</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.tripleStage[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/seat-belt-repair-service/triple-stage-seat-belt" buttonText="Repair My Seat Belt" title="Explore Seat Belt Repair Service" alt="Explore Seat Belt Repair Service" invert />
                            </div>
                        </div>
                    </div>

                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/seat-belt-repair-service/pretensioner">
                            <GatsbyImage image={this.props.pretensioner[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title={this.props.pretensioner[0].node.image.title} alt={this.props.pretensioner[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Pretensioner</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.pretensioner[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/seat-belt-repair-service/pretensioner" buttonText="Repair My Seat Belt" title="Explore Seat Belt Repair Service" alt="Explore Seat Belt Repair Service" invert />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/seat-belt-coloring-service/seat-belt-coloring">
                            <GatsbyImage image={this.props.seatBeltColor[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white max-h-60" title={this.props.seatBeltColor[0].node.image.title} alt={this.props.seatBeltColor[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Seat Belt Coloring</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.seatBeltColor[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/seat-belt-coloring-service/seat-belt-coloring" buttonText="Change My Webbing" title="Explore Seat Belt Coloring Service" alt="Explore Seat Belt Coloring Service" invert />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/seat-belt-webbing-replacement-service/seat-belt-webbing-replacement">
                            <GatsbyImage image={this.props.seatBeltWebbing[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white max-h-60" title={this.props.seatBeltWebbing[0].node.image.title} alt={this.props.seatBeltWebbing[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Webbing Replacement</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.seatBeltWebbing[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/seat-belt-webbing-replacement-service/seat-belt-webbing-replacement" buttonText="Replace My Webbing" title="Explore Seat Belt Webbing Replacement Service" alt="Explore Seat Belt Webbing Replacement Service" invert />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/seat-belt-coloring-service/seat-belt-coloring">
                            <GatsbyImage image={this.props.seatCoverStitching[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white max-h-60" title={this.props.seatCoverStitching[0].node.image.title} alt={this.props.seatCoverStitching[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Deployed Seat Repair</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.seatCoverStitching[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/deployed-seat-repair-service/deployed-seat-repair/" buttonText="Repair My Stitching" title="Explore Deployed Seat Repair Service" alt="Explore Deployed Seat Repair Service" invert />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/seat-reupholstering-service/seat-reupholstering">
                            <GatsbyImage image={this.props.fullSeatReupholstering[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white max-h-60" title={this.props.fullSeatReupholstering[0].node.image.title} alt={this.props.fullSeatReupholstering[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Seat Reupholstering</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.fullSeatReupholstering[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/seat-reupholstering-service/seat-reupholstering" buttonText="Reupholster My Seat" title="Explore Seat Reupholstering Service" alt="Explore Seat Reupholstering Service" invert />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/battery-cable-repair-service/battery-cable">
                            <GatsbyImage image={this.props.batteryCable[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title={this.props.batteryCable[0].node.image.title} alt={this.props.batteryCable[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Battery Cable Repair</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.batteryCable[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/battery-cable-repair-service/battery-cable" buttonText="Repair My Fuse" title="Explore Battery Cable Repair Service" alt="Explore Battery Cable Repair Service" invert />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/steering-column-sensor-repair-service/collapsible-steering-sensor">
                            <GatsbyImage image={this.props.steeringColumn[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title={this.props.steeringColumn[0].node.image.title} alt={this.props.steeringColumn[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Steering Column Sensor Repair</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.steeringColumn[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/steering-column-sensor-repair-service/collapsible-steering-sensor" buttonText="Repair My Sensor" title="Explore Steering Column Sensor Repair Service" alt="Explore Steering Column Sensor Repair Service" invert />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/srs-airbag-module-repair-service/airbag-module-reset">
                            <GatsbyImage image={this.props.srsAirbag[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title={this.props.srsAirbag[0].node.image.title} alt={this.props.srsAirbag[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">SRS Airbag Module Repair</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.srsAirbag[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/srs-airbag-module-repair-service/airbag-module-reset" buttonText="Repair My Module" title="Explore SRS Airbag Module Repair Service" alt="Explore SRS Airbag Module Repair Service" invert />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/convertible-roll-bar-repair-service/convertible-roll-bar">
                            <GatsbyImage image={this.props.convertibleRoll[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title={this.props.convertibleRoll[0].node.image.title} alt={this.props.convertibleRoll[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Convertible Roll Bar Repair</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.convertibleRoll[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-6 md:pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/convertible-roll-bar-repair-service/convertible-roll-bar" buttonText="Repair My Rollbars" title="Explore Convertible Roll Bar Repair Service" alt="Explore Convertible Roll Bar Repair Service" invert />
                            </div>
                        </div>
                    </div>
                        {/* <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                            <Link to="/services/deployed-headrest-repair-service/deployed-headrest">
                                <StaticImage src="../images/deployed-headrest-repair.jpeg" placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title="Deployed Headrest Repair Service" alt="Deployed Headrest Repair Service" />
                            </Link>
                            <div className="px-2">
                                <div className="h-24">
                                    <h3 className="text-xl font-bold pt-5">Deployed Headrest Repair</h3>
                                    <p className="text-sm pt-2">Popped out headrest repair using 100% OEM factory parts to restore it to functioning condition</p>
                                </div>
                                <div className="pt-6 md:pt-3 lg:pt-8 2xl:pt-4">
                                    <RedButton buttonPage="/services/deployed-headrest-repair-service/deployed-headrest" buttonText="Repair My Headrest" title="Explore Deployed Headrest Repair Service" alt="Explore Deployed Headrest Repair Service" invert />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}